<template>
  <div class="yimiao">
    <div>
      <div style="margin-bottom: 100px;">
        <!-- <van-cell-group>
          <van-field v-model="datafrom.OrgName"
            required
            @click="idListOrg = true"
            readonly="readonly"
            label="社区"
            placeholder="请选择社区" />
          <van-popup v-model="idListOrg"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="OrganList"
              value-key="organname"
              @cancel="idListOrg = false"
              @confirm="onidListOrg">
            </van-picker>
          </van-popup>
        </van-cell-group> -->
        <!-- <van-cell-group>
          <van-field v-model="datafrom.Code"
            required
            label="编码"
            placeholder="6位行政区划+4位顺序号" />
        </van-cell-group> -->
        <van-cell-group>
          <van-field v-model="datafrom.Name"
            required
            label="名称"
            placeholder="请输入名称" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Legaler"
            required
            label="法人"
            placeholder="请输入法人" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Header"
            label="负责人"
            required
            placeholder="请输入负责人" />
        </van-cell-group>

        <van-cell-group>
          <van-field v-model="datafrom.TouchTel"
            label="联系电话"
            required
            placeholder="请输入联系电话" />
        </van-cell-group>

        <van-cell-group>
          <van-field v-model="datafrom.Address"
            label="地址"
            required
            placeholder="请输入地址" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Liccode"
            label="营业执照编号"
            placeholder="请输入营业执照编号" />
        </van-cell-group>
        <van-field v-model="datafrom.GradeDesc"
          label="等级"
          required
          placeholder="请选择等级"
          @click="shopTypePicker = true"
          readonly="readonly"
          clickable />
        <van-popup v-model="shopTypePicker"
          position="bottom">
          <van-picker show-toolbar
            :columns="shopType"
            value-key="Name"
            @confirm="shopTypeQr"
            @cancel="shopTypePicker = false" />
        </van-popup>
        <van-cell-group>
          <van-field v-model="datafrom.RoomNum"
            label="房间数"
            placeholder="请输入房间数" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.BedNum"
            label="床位数"
            placeholder="请输入床位数" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.StaffNum"
            label="从业人数"
            placeholder="请输入从业人数" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Introduce"
            label="简介"
            placeholder="请输入简介" />
        </van-cell-group>

        <div class="submit"
          style="padding: 10px 7px">
          <span style="color: red; font-size: 14px; letter-spacing: 8px"></span>
          <span style="color: #646566; font-size: 14px">营业执照</span>
        </div>
        <van-uploader v-model="jkImageList"
          multiple
          class="imgs"
          :max-count="1"
          :after-read="afterRead"
          :before-delete="jkDelete"
          :deletable="true" />
        <div class="submit"
          style="padding: 10px 7px">
          <span style="color: red; font-size: 14px; letter-spacing: 8px"></span>
          <span style="color: #646566; font-size: 14px">民宿照片</span>
        </div>
        <van-uploader v-model="ShopImgList"
          multiple
          class="imgs"
          :max-count="9"
          :after-read="ShopImgRead"
          :before-delete="xcDelete"
          :deletable="true" />

        <van-cell-group>
          <van-cell required
            title="状态">
            <van-radio-group v-model="datafrom.Status"
              direction="horizontal">
              <van-radio name="1">启用</van-radio>
              <van-radio name="2">禁用</van-radio>
            </van-radio-group>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="获取当前定位">
            <van-radio-group v-model="dwIndex"
              direction="horizontal">
              <van-radio name="1"
                @click="dwXuanze(1)">是</van-radio>
              <van-radio name="2"
                @click="dwXuanze(2)">否</van-radio>
            </van-radio-group>
          </van-cell>
        </van-cell-group>
        <div id="allmap"
          v-show="dWshow"
          style="height: 160px; width: 90%; margin: auto"></div>
      </div>
      <div style="position: fixed;bottom: 0px;width:100%;padding: 15px 0;background-color: white;">
        <div style="width:100%; text-align: center;">
          <van-button class="button"
            @click="postfrom(1)"
            style="width:95%;border-radius: 5px;"
            type="info">提交</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="//api.map.baidu.com/api?v=3.0&ak=L2s5R0CE405alnjKlsp35mNTySNAvZtd"></script>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
import { loadBMap } from "../fangyi/baiduLocation.js";
import { WxGetShopTypeList } from "@/api/shop";
import { GetCodeEntrysList } from "@/api/wangge";
import { HGetMyHotel } from "@/api/RealInfo";
Vue.use(Toast);
import {
  WeGetRegionList,
  WeGetBuildRoomList,
  HSaveHotel,
  WeGetAppletOrganList,
  GetOrgansNullToken,
  WxGetMyShop,
  WxReBindShop,
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      // 营业执照
      jkImageList: [],
      //商铺图片
      ShopImgList: [],
      OrganList: [],
      ridlist: [],
      BIdlist: [],
      BUIdlist: [],
      BFIdlist: [],
      BRIdlist: [],
      diqu: [],
      guanxi: [],
      minzu: [],
      xveli: [],
      zhuangtai: [],
      hunyin: [],
      ocodelist: [],
      minDate: new Date(2021, 0),
      maxDate: new Date(2025, 0),
      NativeCantonCodedata: [],
      dwIndex: "2",
      sexlist: [
        { id: "1", Name: "男" },
        { id: "2", Name: "女" },
      ],
      yimiaolist: [
        { id: "0", Name: "未接种" },
        { id: "1", Name: "第一针" },
        { id: "2", Name: "第二针" },
      ],
      idList: {
        organCode: "",
        RId: undefined,
      },
      datafrom: {
        HotelId: 0, //id、
        OpenID: "",
        Name: "", // 名称、
        Legaler: "", // 负责人
        TouchTel: "", // 联系电话
        Address: "", // 地址
        Coordinate: "", // 坐标
        Liccode: "", // 营业执照编号
        LicImg: "", // 营业执照照片
        Grade: "", // 等级
        RoomNum: "", // 房间数
        BedNum: "", // 床位数
        StaffNum: "", // 从业人数
        Introduce: "", // 简介
        Imgs: "", // 照片
        Status: "1",
        Remark: "",
      },
      ImgsList: [],
      shopType: [
        { Id: 1, Name: '五星' },
        { Id: 2, Name: '四星' },
        { Id: 3, Name: '三星' },
        { Id: 4, Name: '二星' },
        { Id: 5, Name: '二星以下' },
      ],
      shopTypePicker: false, //商铺类型
      idListRId: false,
      idListOrg: false,
      addshow: false,
      verifyshow: true,
      OCodeshow: false,
      sexshow: false,
      Nationshow: false,
      yimiao: false,
      datafromBackTime: false,
      datafromOutTime: false,
      dWshow: false,
      bangdingShow: true,
      bjZuoBiao: "",
      shopAdd: this.$route.query.add,
    };
  },
  created () {
    this.GetOrgansNullToken();
    this.shopXq();
    // this.zidianlist();
    this.datafrom.ShopCate = this.$route.query.shopCate;
  },
  methods: {
    shopXq: function () {
      // console.log(this.$route);
      if (this.$route.params.add == "0") {
        this.datafrom = {
          HotelId: 0, //id、
          OpenID: "",
          Name: "", // 名称、
          Legaler: "", // 负责人
          TouchTel: "", // 联系电话
          Address: "", // 地址
          Coordinate: "", // 坐标
          Liccode: "", // 营业执照编号
          LicImg: "", // 营业执照照片
          Grade: "", // 等级
          RoomNum: "", // 房间数
          BedNum: "", // 床位数
          StaffNum: "", // 从业人数
          Introduce: "", // 简介
          Imgs: "", // 照片
          Status: "1",
          Remark: "",
        };
      } else if (this.$route.params.add == "1") {
        HGetMyHotel({
          openID: getOpenId(),
          hotelId: this.$route.params.hotelId,
        }).then((res) => {
          this.bangdingShow = true;
          if (res.data.code == 0) {
            this.datafrom = res.data.data;
            this.bjZuoBiao = res.data.data.Coordinate;
            this.datafrom.Status = res.data.data.Status + "";
            this.datafrom.OCode = res.data.data.OCode;
            this.datafrom.OrgName = res.data.data.OName;
            this.datafrom.GradeDesc = res.data.data.GradeDesc;
            this.RegionList(res.data.data.OCode);
            // console.log(res.data.data);
            //商铺照片
            if (res.data.data.Imgs != "" && res.data.data.Imgs != null) {
              this.ShopImgLists = res.data.data.Imgs.split(',');
              var arr = [];
              for (let i = 0; i < this.ShopImgLists.length; i++) {
                const e = this.ShopImgLists[i];
                arr.push({ url: e });
              }

              this.ShopImgList = arr;
              // console.log(this.ShopImgList);
              this.datafrom.Imgs = res.data.data.Imgs;
            } else {
              this.ShopImgList = [];
            }
            if (res.data.data.LicImg != "") {
              //营业执照
              this.jkImageLists = [res.data.data.LicImg,
              ];
              var arrYe = [];
              this.jkImageLists.forEach(function (imgUrl) {
                arrYe.push({ url: imgUrl });
              });
              this.jkImageList = arrYe;
              this.datafrom.LicImg = res.data.data.LicImg;
            }
            // this.datafrom.ShopTypeKind = res.data.data.ShopTypeKind;
            this.datafrom.Region = res.data.data.RName;
          }
          if (this.$route.query.add == "3") {
            this.bangdingShow = false;
          }
        });
      }
    },
    dwXuanze: function (row) {
      if (row == "1") {
        this.dingwei();
        this.dWshow = true;
      } else {
        this.dWshow = false;
        this.Coordinate = this.bjZuoBiao;
      }
    },
    dingwei: function () {
      var that = this;
      // 百度地图API功能
      window.initBaiduMapScript = () => {
        that.BMap = window.BMap;
        var map = new window.BMap.Map("allmap");
        var point = new that.BMap.Point(116.331398, 39.897445);
        map.centerAndZoom(point, 18);

        var geolocation = new that.BMap.Geolocation();
        var Coordinate = "";
        geolocation.getCurrentPosition(
          function (r) {
            if (this.getStatus() == BMAP_STATUS_SUCCESS) {
              var mk = new BMap.Marker(r.point);
              map.addOverlay(mk);
              map.panTo(r.point);
              this.Coordinate = [
                { lng: r.point.lng, lat: r.point.lat, of: "inner" },
              ];
              // console.log(this.Coordinate);
              that.dingweiList(this.Coordinate);
              // alert('您的位置：' + r.point.lng + ',' + r.point.lat);
            } else {
              alert("failed" + this.getStatus());
            }
          },
          { enableHighAccuracy: true }
        );
        // console.log(geolocation);
        this.dingweiList(geolocation);
      };
      loadBMap("initBaiduMapScript");
    },
    dingweiList: function (geolocation) {
      // console.log(geolocation);
      this.Coordinate = geolocation;
    },
    // 字典查询
    zidianlist: function () {
      // 商铺类型
      WxGetShopTypeList({ shopCate: 2 }).then((res) => {
        if (res.data.code == "0") {
          this.shopType = res.data.data;
        }
      });
      // GetCodeEntrysList({ kind: "310" }).then((res) => {
      //   this.shopType = res.data.data;
      // });
    },
    // 确认商铺类型
    shopTypeQr (val) {
      this.datafrom.Grade = val.Id;
      this.datafrom.GradeDesc = val.Name;
      this.shopTypePicker = false;
    },
    //上传营业执照
    afterRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              // console.log(res.data.picurl);
              that.datafrom.LicImg = res.data.picurl;
              // that.datafrom.EvalImgs.push(res.data.data);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除营业执照
    jkDelete (file) {
      this.jkImageList = [];
      this.datafrom.LicImg = "";
    },
    //上传民宿图片
    ShopImgRead (file) {
      var that = this;
      // this.ImgsList = []
      // console.log(this.ImgsList);
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              // console.log(res.data.picurl[0]);
              // that.datafrom.Imgs = res.data.picurl;
              file.status = "";
              file.message = "";
              that.ImgsList.push({ url: res.data.picurl[0] });

              // console.log(that.ImgsList);
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除商铺图片
    xcDelete (file, detail) {
      // console.log(file, detail);
      this.ImgsList.splice(detail.index, 1);
      this.ShopImgList.splice(detail.index, 1);
      // this.ShopImgList = [];
      // this.datafrom.Imgs = "";
    },
    // 提交 
    postfrom: function (row) {

      // console.log(this.datafrom);
      if (this.datafrom.ShopCate == 1) {

        if (this.datafrom.Name == "") {
          Toast.fail("请输入民宿名称");
          return false;
        }
        if (this.datafrom.Legaler == "") {
          Toast.fail("请输入法人");
          return false;
        }
        if (this.datafrom.Header == "") {
          Toast.fail("请输入负责人");
          return false;
        }
        if (this.datafrom.Mobile == "") {
          Toast.fail("请输入联系电话");
          return false;
        }

        if (this.datafrom.Address == "") {
          Toast.fail("请输入详细地址");
          return false;
        }
      } else {
        if (this.datafrom.Name == "") {
          Toast.fail("请输入名称");
          return false;
        }
        if (this.datafrom.Header == "") {
          Toast.fail("请输入负责人");
          return false;
        }
        if (this.datafrom.TouchTel == "") {
          Toast.fail("请输入联系电话");
          return false;
        }
        if (this.datafrom.Grade == "") {
          Toast.fail("请选择等级");
          return false;
        }
      }
      // console.log(getOpenId());
      this.datafrom.OpenID = getOpenId();
      // this.datafrom.OpenID = "oebDw5bQTDtkwSQJAsKA-uKSWitM";
      this.datafrom.ShopCate = this.$route.query.shopCate;
      // this.datafrom.OpenID = "123321";
      if (this.Coordinate == "") {
        this.datafrom.Coordinate = "";
      } else {
        if (this.dWshow) {
          this.datafrom.Coordinate = JSON.stringify(this.Coordinate);
        } else {
          this.datafrom.Coordinate = this.bjZuoBiao;
        }
      }
      this.datafrom.LicImg = this.datafrom.LicImg + '';

      // this.datafrom.Imgs = this.ImgsList + '';
      var imgList = []
      for (let i = 0; i < this.ShopImgList.length; i++) {
        const e = this.ShopImgList[i];
        if (e.url) {
          imgList.push(e.url)
        }
      }
      this.ImgsList.forEach(e => {
        imgList.push(e.url)
      });
      this.datafrom.Imgs = imgList + '';
      if (row == "1") {
        HSaveHotel(this.datafrom).then((res) => {
          if (res.data.code == 0) {
            Dialog.alert({
              title: "提示",
              message: "提交成功!",
            }).then(() => {
              this.$router.go(-1);
              // this.$router.push({
              //   name: "register",
              // });
            });
          } else {
            Dialog.alert({
              title: "提示",
              message: "提交失败!" + res.data.msg,
            }).then(() => {
              // on close
            });
          }
        });
      } else if (row == "2") {
        WxReBindShop(this.datafrom).then((res) => {
          if (res.data.code == 0) {
            Dialog.alert({
              title: "提示",
              message: "绑定成功!",
            }).then(() => {
              this.$router.go(-1);
              // this.$router.push({
              //   name: "register",
              // });
            });
          } else {
            Dialog.alert({
              title: "提示",
              message: "提交失败!" + res.data.msg,
            }).then(() => {
              // on close
            });
          }
        });
      }
    },
    gitocodelist () {
      GetOrgansNullToken().then((res) => {
        this.ocodelist = res.data.data;
      });
    },
    // 社区列表
    GetOrgansNullToken: function (row) {
      GetOrgansNullToken({ level: 4, kind: 1 }).then((res) => {
        this.OrganList = res.data.data;
      });
    },
    // 小区(组)列表
    RegionList: function (row) {
      WeGetRegionList({ organCode: row }).then((res) => {
        this.ridlist = res.data.data;
      });
    },
    // 房屋列表
    BuildRoomList: function (val) {
      WeGetBuildRoomList({
        bId: this.idList.BId,
        buId: this.idList.buId,
        bfId: val,
      }).then((res) => {
        // console.log(res);
        this.BRIdlist = res.data.data;
      });
    },
    fanhui: function () {
      this.$router.go(-1);
    },
    // 社区选择器
    onidListOrg (val) {
      this.datafrom.OCode = val.organcode;
      this.datafrom.OrgName = val.organname;
      this.idListOrg = false;
      this.RegionList(val.organcode);
      this.datafrom.Region = "";
      // this.BuildList(val.RId);
    },
    // 小区(组)选择器
    onidListRId (val) {
      this.datafrom.RId = val.RId;
      this.datafrom.Region = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 性别选择器
    onsexshow (val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },

    // 消防器材年检日期
    ondatafromOutTime (val) {
      this.datafrom.YearCheckTime = this.dateformat(val);
      this.datafromOutTime = false;
    },
    // 社区
    onocode (val) {
      // console.log(val);
      this.datafrom.OrgName = val.selectedOptions[0].OrganName;
      this.RegionList(val.value);
      this.OCodeshow = false;
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let houser = val.getHours();
      let Minutes = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (houser >= 1 && houser <= 9) {
        houser = `0${houser}`;
      }
      if (Minutes >= 1 && Minutes <= 9) {
        Minutes = `0${Minutes}`;
      }
      // return `${year}-${month}-${day} ${houser}:${Minutes}`;
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style>
.imgs {
  margin: 0 15px;
}

.yimiao .inputFrom {
  margin: 15px 8%;
}

.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}

.yimiao .buttons {
  text-align: center;
}

.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}

.buttons {
  text-align: center;
}

.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>